@import "../themes";

@mixin generate-scroll($themes: $themes) {
  @each $theme, $map in $themes {
    .#{$theme}-theme {
      $scrollbar: map-get($map, scrollbar);

      /* width */
      ::-webkit-scrollbar {
        width: 14px;
        height: 14px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        min-height: 32px;
        min-width: 32px;
        border-style: solid;
        border-color: transparent;
        border-width: 4px;
        border-radius: 14px;
        background-clip: padding-box;
      }

      ::-webkit-scrollbar-thumb {
        background-color: map-get($scrollbar, tumb-background);
      }

      ::-webkit-scrollbar-thumb:hover {
        background-color: map-get($scrollbar, tumb-background-hover);
      }

      &::-webkit-scrollbar-thumb {
        background-color: map-get($scrollbar, tumb-background);
      }

      scrollbar-color: map-get($scrollbar, tumb-background) transparent;

      ::-webkit-scrollbar-corner {
        background-color: transparent
      }

    }
  }
}
