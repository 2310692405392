@import "variables";

.input-border-duration-container > * {
  padding: 0 8px;
  border-radius: 4px;
  border: solid 1px #cccccc;
  height: 33px;
  display: flex;
  align-items: center;
}
