@layer primeng {
    .p-datatable {
        position: relative;
    }

    .p-datatable > .p-datatable-wrapper {
        overflow: auto;
    }

    .p-datatable-table {
        border-spacing: 0px;
        width: 100%;
    }

    .p-datatable .p-sortable-column {
        cursor: pointer;
        user-select: none;
    }

    .p-datatable .p-sortable-column .p-column-title,
    .p-datatable .p-sortable-column .p-sortable-column-icon,
    .p-datatable .p-sortable-column .p-sortable-column-badge {
        vertical-align: middle;
    }

    .p-datatable .p-sortable-column .p-icon-wrapper {
        display: inline;
    }

    .p-datatable .p-sortable-column .p-sortable-column-badge {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .p-datatable-hoverable-rows .p-selectable-row {
        cursor: pointer;
    }

    /* Scrollable */
    .p-datatable-scrollable > .p-datatable-wrapper {
        position: relative;
    }

    .p-datatable-scrollable-table > .p-datatable-thead {
        position: sticky;
        top: 0;
        z-index: 2;
    }

    .p-datatable-scrollable-table > .p-datatable-frozen-tbody {
        position: sticky;
        z-index: 1;
    }

    .p-datatable-scrollable-table > .p-datatable-tfoot {
        position: sticky;
        bottom: 0;
        z-index: 1;
    }

    .p-datatable-scrollable .p-frozen-column {
        position: sticky;
        background: inherit;
        z-index: 1;
    }

    .p-datatable-scrollable th.p-frozen-column {
        z-index: 1;
    }

    .p-datatable-flex-scrollable {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .p-datatable-flex-scrollable > .p-datatable-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 100%;
    }

    .p-datatable-scrollable-table > .p-datatable-tbody > .p-rowgroup-header {
        position: sticky;
        z-index: 2;
    }

    /* Resizable */
    .p-datatable-resizable-table > .p-datatable-thead > tr > th,
    .p-datatable-resizable-table > .p-datatable-tfoot > tr > td,
    .p-datatable-resizable-table > .p-datatable-tbody > tr > td {
        overflow: hidden;
        white-space: nowrap;
    }

    .p-datatable-resizable-table > .p-datatable-thead > tr > th.p-resizable-column:not(.p-frozen-column) {
        background-clip: padding-box;
        position: relative;
    }

    .p-datatable-resizable-table-fit > .p-datatable-thead > tr > th.p-resizable-column:last-child .p-column-resizer {
        display: none;
    }

    .p-datatable .p-column-resizer {
        display: block;
        position: absolute !important;
        top: 0;
        right: 0;
        margin: 0;
        width: 0.5rem;
        height: 100%;
        padding: 0px;
        cursor: col-resize;
        border: 1px solid transparent;
    }

    .p-datatable .p-column-resizer-helper {
        width: 1px;
        position: absolute;
        z-index: 10;
        display: none;
    }

    .p-datatable .p-row-editor-init,
    .p-datatable .p-row-editor-save,
    .p-datatable .p-row-editor-cancel {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
    }

    /* Expand */
    .p-datatable .p-row-toggler {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
    }

    /* Reorder */
    .p-datatable-reorder-indicator-up,
    .p-datatable-reorder-indicator-down {
        position: absolute;
    }

    .p-datatable-reorderablerow-handle {
        cursor: move;
    }

    [pReorderableColumn] {
        cursor: move;
    } 

    /* Loader */
    .p-datatable .p-datatable-loading-overlay {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
    }

    /* Filter */
    .p-column-filter-row {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .p-column-filter-menu {
        display: inline-flex;
    }

    .p-column-filter-row p-columnfilterformelement {
        flex: 1 1 auto;
        width: 1%;
    }

    .p-column-filter-menu-button,
    .p-column-filter-clear-button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
        overflow: hidden;
        position: relative;
    }

    .p-column-filter-overlay {
        position: absolute;
        top: 0;
        left: 0;
    }

    .p-column-filter-row-items {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .p-column-filter-row-item {
        cursor: pointer;
    }

    .p-column-filter-add-button,
    .p-column-filter-remove-button {
        justify-content: center;
    }

    .p-column-filter-add-button .p-button-label,
    .p-column-filter-remove-button .p-button-label {
        flex-grow: 0;
    }

    .p-column-filter-buttonbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .p-column-filter-buttonbar .p-button {
        width: auto;
    }

    /* Responsive */
    .p-datatable-tbody > tr > td > .p-column-title {
        display: none;
    }

    /* Virtual Scroll */
    .p-datatable-scroller-spacer {
        display: flex;
    }

    .p-datatable .p-scroller .p-scroller-loading {
        transform: none !important;
        min-height: 0;
        position: sticky;
        top: 0;
        left: 0;
    }
}
